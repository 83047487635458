let traductions;
let traductions_list;
let traductions_dict;

async function refresh_traductions(language) {
    let language_cookie = getCookie('pasta-language')
    if (language_cookie === language) {return}
    const result = await makeGetRequest(`${quart_api_url}/traductions?language=${language}`);
    if (result['code'] === 200) {
        if (language_cookie === null || language_cookie !== result['response']['data']['traductions_cookies']) {
            setCookie('pasta-language', result['response']['data']['traductions_cookies'], 365)
        }
        traductions = result['response']['data']['traductions']
        traductions_list = result['response']['data']['traductions_lists']
        traductions_dict = result['response']['data']['traductions_dict']
        let nav__bar_traduction_ul = document.querySelectorAll("[id=nav__bar-traduction-ul]");
        nav__bar_traduction_ul.forEach(elm => {
            elm.innerHTML = ""
        })
        for (let d in traductions_dict) {
                nav__bar_traduction_ul.forEach(elm => {
                let li = document.createElement("li");
                let p = document.createElement("p");
                let p_txt = document.createTextNode(`${traductions_dict[d]}`);
                p.className = "button2";
                let func = async function () {
                    await refresh_traductions(this.data);
                }
                p.onclick = func.bind({data: d});
                p.appendChild(p_txt)
                li.appendChild(p)
                elm.appendChild(li)
            })
        }
        let rangeTrads = document.querySelectorAll('[data-traduction]');
        const regex_traduction = /%(\w+)%/gm;
        const regex_traduction_script = /<script[\s\S]*?>([\s\S]*?)<\/script>/gi;
        rangeTrads.forEach(TradElm => {
            let raw_text = traductions[`${TradElm.dataset.traduction}`]
            let m;
            while ((m = regex_traduction.exec(raw_text)) !== null) {
                if (m.index === regex_traduction.lastIndex) {
                    regex_traduction.lastIndex++;
                }

                let placeholder = TradElm.getAttribute(`data-traduction-placeholder-${m[1]}`)

                let v = regex_traduction_script.exec(placeholder)

                if (v !== null) {
                    try {
                        let F=eval(v[1])
                        raw_text = raw_text.replace(m[0], `${F}`);
                    } catch {
                        raw_text = raw_text.replace(m[0], placeholder);
                    }
                } else {
                    raw_text = raw_text.replace(m[0], placeholder);
                }
            }

            TradElm.innerHTML = raw_text
        })
    } else {
        return window.location.href = "/error-traductions";
    }
}

async function get_traductions() {
    let language_navigator = navigator.language
    let language_cookie = getCookie('pasta-language')
    let language
    if (language_cookie === null) {
        language = language_navigator
    } else {
        language = language_cookie
    }
    const result = await makeGetRequest(`${quart_api_url}/traductions?language=${language}`);
    if (result['code'] === 200) {
        if (language_cookie === null || language_cookie !== result['response']['data']['traductions_cookies']) {
            setCookie('pasta-language', result['response']['data']['traductions_cookies'], 365)
        }
        traductions = result['response']['data']['traductions']
        traductions_list = result['response']['data']['traductions_lists']
        traductions_dict = result['response']['data']['traductions_dict']
        let nav__bar_traduction_ul = document.querySelectorAll("[id=nav__bar-traduction-ul]");
        nav__bar_traduction_ul.forEach(elm => {
            elm.innerHTML = ""
        })
        for (let d in traductions_dict) {
            nav__bar_traduction_ul.forEach(elm => {
                let li = document.createElement("li");
                let p = document.createElement("p");
                let p_txt = document.createTextNode(`${traductions_dict[d]}`);
                p.className = "button2";
                let func = async function () {
                    await refresh_traductions(this.data);
                }
                p.onclick = func.bind({data: d});
                p.appendChild(p_txt)
                li.appendChild(p)
                elm.appendChild(li)
            })
        }
        let rangeTrads = document.querySelectorAll('[data-traduction]');
        const regex_traduction = /%(\w+)%/gm;
        const regex_traduction_script = /<script[\s\S]*?>([\s\S]*?)<\/script>/gi;
        rangeTrads.forEach(TradElm => {
            let raw_text = traductions[`${TradElm.dataset.traduction}`]
            let m;
            while ((m = regex_traduction.exec(raw_text)) !== null) {
                if (m.index === regex_traduction.lastIndex) {
                    regex_traduction.lastIndex++;
                }

                let placeholder = TradElm.getAttribute(`data-traduction-placeholder-${m[1]}`)

                let v = regex_traduction_script.exec(placeholder)

                if (v !== null) {
                    try {
                        let F=eval(v[1])
                        raw_text = raw_text.replace(m[0], `${F}`);
                    } catch {
                        raw_text = raw_text.replace(m[0], placeholder);
                    }
                } else {
                    raw_text = raw_text.replace(m[0], placeholder);
                }
            }

            TradElm.innerHTML = raw_text
        })
    } else {
        return window.location.href = "/error-traductions";
    }
}

const onloadtraduction = new CustomEvent("onloadtraduction", {
  bubbles: true
});

window.addEventListener("load", async (event) => {
    await get_traductions();
    window.dispatchEvent(onloadtraduction)
});